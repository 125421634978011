import {
  GET_LEAD_LIST, LEAD_LIST_STATUS,
  LOAD_BASE_FILTERS,
  UPDATING_LEAD_STATUS,
  UPDATE_LEAD_TO_LIST,
  UPDATE_REJECTING_STATUS,
  UPDATE_ACCEPTING_STATUS,
  DELETE_REJECTED_LEAD,
  UPDATING_CALL_LEAD_STATUS,
  AVAILABLE_DATA
} from './LeadActions';

const initialState = {
  list: [],
  isFetching: false,
  count: 0,
  pageNum: 1,
  pageSize: 25,
  baseLeadFilters: {},
  leadSortFilters: [],
  isRejectingLead: false,
  isUpdatingLead: false,
  monthLeadCount:0,
  availabledata:[]
};

const LeadReducer = (state = initialState, action) => {
  let leadList = [...state.list]
  switch (action.type) {

    case DELETE_REJECTED_LEAD:
      leadList = leadList.filter(item => item._id !== action.leadId);
      return {
        ...state,
        list: leadList,
        isRejectingLead: action.isRejectingLead
      };

    case UPDATE_REJECTING_STATUS:
      leadList = leadList.map(lead => {
        let newLead = {...lead}
        if(newLead._id === action.leadId) {
          newLead.isRejectingLead = action.isRejectingLead;
        }
        return newLead;
      });

      return {
        ...state,
        isRejectingLead: action.isRejectingLead,
        list: leadList,
      };

    case UPDATE_ACCEPTING_STATUS:
      leadList = leadList.map(lead => {
        let newLead = {...lead}
        if(newLead._id === action.leadId) {
          newLead.isAcceptingLead = action.isAcceptingLead;
        }
        return newLead;
      });

      return {
        ...state,
        list: leadList,
      };

    case UPDATING_LEAD_STATUS:
      leadList = leadList.map(lead => {
        let newLead = {...lead}
        if(newLead._id === action.leadId) {
          newLead.isUpdatingLead = action.isUpdatingLead;
        }
        return newLead;
      });

      return {
        ...state,
        list: leadList,
        isUpdatingLead: action.isUpdatingLead
      };

    case UPDATE_LEAD_TO_LIST:
      leadList = leadList.map(lead => {
        let newLead = {...lead}
        if(newLead._id === action.lead._id) {
          newLead = action.lead;
          newLead.isRejectingLead = action.isRejectingLead;
          newLead.isAcceptingLead = action.isAcceptingLead;
          newLead.isUpdatingLead = action.isUpdatingLead;
        }
        return newLead;
      });
      return {
        ...state,
        isUpdatingLead: action.isUpdatingLead,
        isRejectingLead: action.isRejectingLead,
        list: leadList,
      };

    case LEAD_LIST_STATUS:
      return {
        ...state,
        isFetching: action.isFetching,
      };
      case AVAILABLE_DATA:
        return {
          ...state,
          availabledata: action.availabledata,
        };

    case LOAD_BASE_FILTERS:
      return {
        ...state,
        baseLeadFilters: action.baseLeadFilters,
        leadSortFilters: action.leadSortFilters,
      };

    case GET_LEAD_LIST:
      return {
        ...state,
        list: action.list,
        count: action.count,
        pageNum: action.pageNum,
        isFetching: action.isFetching,
        monthLeadCount:action.monthLeadCount,
      };

    default :
      return {
        ...state,
      };
  }
};


//Selectors
export const getLeadList = state => state.leads.list ? state.leads.list : [];
export const getLeadCount = state => state.leads.count ? state.leads.count : 0;
export const getmonthLeadCount = state => state.leads.monthLeadCount ? state.leads.monthLeadCount : 0;
export const getLeadPageNum = state => state.leads.pageNum;
export const getLeadPageSize = state => state.leads.pageSize;
export const getLeadFetchingStatus = state => state.leads.isFetching;
export const getLeadListFilters = state => state.leads.baseLeadFilters;
export const getLeadSortingParams = state => state.leads.leadSortFilters;
export const getRejectingLeadStatus = state => state.leads.isRejectingLead;
export const getUpdatingLeadStatus = state => state.leads.isUpdatingLead;
export const getAvaliablityData = state => state.leads.availabledata?.eventsMap || {};


export default LeadReducer;