const language = {
  "en": {
    "APP": {
      "EMAIL_VERIFICATION_PAGE" : {
        "EMAIL_VERIFICATION_PAGE_HEADING" : 'Verify your email',
        "EMAIL_VERIFICATION_PAGE_SUB_HEADING" : 'Verify your email with just one click',
        "EMAIL_VERIFICATION_PAGE_VERIFY_BUTTON" : 'Verify Email',
        "EMAIL_VERIFICATION_PAGE_COMPLETE_HEADING" : 'Hooray! your email is verified successfully',
        "EMAIL_VERIFICATION_PAGE_COMPLETE_SUB_HEADING" : 'please proceed to the homepage to login'
      },
      "LIST_YOUR_BUSINESS_PAGE" : {
        "LIST_YOUR_BUSINESS_PAGE_HEADING" : 'List Your Business',
        "LIST_YOUR_VENDOR_TYPE": "Vendor Type",
        "LIST_YOUR_BUSINESS_PAGE_SUB_HEADING" : 'Let\'s help people plan events with ease together !',
        "LIST_YOUR_BUSINESS_PAGE_PROPERTY_NAME_LABEL" : 'Property Name',
        "LIST_YOUR_BUSINESS_PAGE_VENDOR_NAME_LABEL" : 'Vendor Name',
        "LIST_YOUR_BUSINESS_PAGE_BUSINESS_NAME_LABEL" : ' Business City',
        "LIST_YOUR_BUSINESS_PAGE_CITY_LABEL" : '--Select City --',
        "LIST_YOUR_BUSINESS_PAGE_SUB_AREA_LABEL" : 'Sub Area',
        "LIST_YOUR_BUSINESS_PAGE_WEBSITE_NAME_LABEL" : 'Website',
        "LIST_YOUR_BUSINESS_PAGE_CONTACT_NAME_LABEL" : 'Contact Name',
        "LIST_YOUR_BUSINESS_PAGE_CONTACT_NUMBER_LABEL" : 'Contact Number',
        "LIST_YOUR_BUSINESS_PAGE_CONTACT_EMAIL_LABEL" : 'Contact Email',
        "LIST_YOUR_BUSINESS_PAGE_VERIFY_OTP_TEXT" : 'Verify OTP',
        "LIST_YOUR_BUSINESS_PAGE_RESEND_OTP_TEXT" : 'Resend OTP',
        "LIST_YOUR_BUSINESS_PAGE_VERIFICATION_COMPLETE_HOMEPAGE" : 'Homepage',
        "LIST_YOUR_BUSINESS_PAGE_OTP_VERIFICATION_COMPLETE_TEXT" : "Phone Verification complete our agent will be in touch with you soon",
        "LIST_YOUR_BUSINESS_PAGE_ENTER_OTP_TEXT" : "Please enter the 4 digit OTP",
        "LIST_YOUR_VENDOR_TYPE_LABEL": "-- Select Your Vendor Type--",
      }
    },
    "DASHBOARD": {
      "HEADING_SUB_TEXT" : "Find out if your Customers are getting timely responses & getting their issues resloved quickly!",
      "NEW_LEADS_HEADING": "New Leads",
      "TOTAL_LEADS_HEADING": "Total Leads",
      "ACTIVE_LEADS_HEADING": "Active Leads",
      "BOOKINGS_HEADING": "Bookings",
      "CURRENT_MONTH_SUB": "(Current Month)",
      "REVENUE_HEADING": "Revenue",
      "BAR_GRAPH_HEADING": "Bargraph of Daily Leads",
      "CURRENT_MONTH_OPTION": 'Current Month',
      "LAST_MONTH_OPTION": 'Last Month',
      "LAST_THIRTY_DAYS_OPTION": 'Last 30 days',
      "LAST_SEVEN_DAYS_OPTION": 'Last 7 days',
      "LEAD_FILTER_OPTION":'lead graph',
      "ACCEPTED_LEAD_HEADING": "Accepted Leads",
      "REJECTED_LEAD_HEADING": "Rejected Leads",
      "CLOSED_LOST_LEAD_HEADING": "Closed Lost Leads",
      "CLOSED_WON_LEAD_HEADING": "Closed Won Leads",
      "ALL_LEAD_HEADING": "All Leads",
      "CUSTOM_OPTION": "Custom Select"

    },
    "BOOKING": {
      "BOOKINGS_HEADING": "Bookings",
      "BOOKINGS_SUB_HEADING" : "Find out about your bookings and payments status",

      "BOOKINGS_DASHBOARD_FILTER_STATUS": 'Status',
      "BOOKINGS_DASHBOARD_FILTER_SEARCH_BY_DATE": 'Search by Date',
      "BOOKINGS_DASHBOARD_FILTER_SEARCH_BY_DATE_OPTION_LEAD_DATE": 'Lead Date',
      "BOOKINGS_DASHBOARD_FILTER_SEARCH_BY_DATE_OPTION_EVENT_DATE": 'Event Date',
      "BOOKINGS_DASHBOARD_FILTER_SEARCH": 'Search',
      "BOOKINGS_DASHBOARD_FILTER_SORT_BY_HEADING": 'Sort by',
      "BOOKINGS_DASHBOARD_FILTER_STATUS_BY_HEADING": 'All',
      "BOOKINGS_DASHBOARD_FILTER_SEARCH_BY_TEXT": 'Search by text',
      "BOOKINGS_DASHBOARD_FILTER_HIDE_SEARCH_BY_TEXT": 'Hide Search by text',
      "BOOKINGS_DASHBOARD_FILTER_SEARCH_PLACEHOLDER": "Search Here",
      "BOOKINGS_DASHBOARD_FILTER_APPLY": "Apply",
      "BOOKINGS_DASHBOARD_FILTER_RESET": "Reset",
      "BOOKINGS_DASHBOARD_FILTER_HEADING": "Filter",
      "BOOKINGS_DASHBOARD_FILTER_DATE_SELECT": "Select Date Range",
      "BOOKINGS_DASHBOARD_FILTER_DATE_SELECT_START": "Select Start Date",
      "BOOKINGS_DASHBOARD_FILTER_DATE_SELECT_END": "Select End Date",

      "BOOKINGS_DASHBOARD_LEAD_LIST_HEADER_ENQUIRY" : 'Enquiry Details',
      "BOOKINGS_DASHBOARD_LEAD_LIST_HEADER_EVENT" : 'Event',
      "BOOKINGS_DASHBOARD_LEAD_LIST_HEADER_PAYMENT" : 'Payment',
      "BOOKINGS_DASHBOARD_LEAD_LIST_HEADER_STATUS" : 'Status',
      "BOOKINGS_DASHBOARD_LEAD_LIST_EDIT_BOOKING" : 'Edit',


      "BOOKINGS_DASHBOARD_LEAD_CONTACT_NAME" : 'Name',
      "BOOKINGS_DASHBOARD_LEAD_CONTACT_MOBILE_NUMBER" : 'Mobile no.',
      "BOOKINGS_DASHBOARD_LEAD_LEAD_DATE" : 'Lead Date',
      "BOOKINGS_DASHBOARD_LEAD_BOOKING_DATE" : 'Booking Date',
      "BOOKINGS_DASHBOARD_LEAD_EVENT_DATE" : 'Event Date',

      "BOOKINGS_DASHBOARD_LEAD_OCCASION" : 'Occasion',
      "BOOKINGS_DASHBOARD_LEAD_BUDGET" : 'Budget',
      "BOOKINGS_DASHBOARD_LEAD_BUDGET_SUB" : 'per person',
      "BOOKINGS_DASHBOARD_LEAD_PACKAGE_TYPE" : 'Package Type',
      "BOOKINGS_DASHBOARD_LEAD_GUEST" : 'Guest',
      "BOOKINGS_DASHBOARD_LEAD_CALL_STATUS": 'Call Status',

      "BOOKINGS_DASHBOARD_LEAD_CALL_ADVANCE_RECEIVED": 'Advance Received',
      "BOOKINGS_DASHBOARD_LEAD_CALL_PAYMENT_DUE": 'Payment Due',
      "BOOKINGS_DASHBOARD_LEAD_CALL_PAYMENT_DUE_SINCE": 'Due Since',
      "BOOKINGS_DASHBOARD_LEAD_CALL_PAYMENT_DUE_SINCE_DAYS": 'Days',
      "BOOKINGS_DASHBOARD_LEAD_CALL_TOTAL_REVENUE": 'Total Revenue',

    },
    "LEAD_MANAGE": {
      "NAV_BAR_EVENT_ICON_TITLE": "Event Details",
      "NAV_BAR_PACKAGE_ICON_TITLE": "Package",
      "NAV_BAR_SMS_ICON_TITLE": "Sms",
      "NAV_BAR_EMAIL_ICON_TITLE": "Email",
      "NAV_BAR_VISIT_ICON_TITLE": "Visit",

      "BASIC_DETAIL_PAGE_TITLE": "Basic Details",
      "BASIC_DETAIL_PAGE_SAVE": "Save",
      "BASIC_DETAIL_PAGE_ADMIN_TEXT" : 'Admin',
      "BASIC_DETAIL_PAGE_CUSTOMER_TEXT" : 'Customer',
      "BASIC_DETAIL_PAGE_EVENT_TITLE": "Event Details",
      "BASIC_DETAIL_PAGE_VERIFIED_ICON_TEXT": "Verified",
      "BASIC_DETAIL_PAGE_NAME_TEXT": "Name",
      "BASIC_DETAIL_PAGE_EMAIL_TEXT": "Email Id",
      "BASIC_DETAIL_PAGE_MOBILE_TEXT": "Mobile No",
      "BASIC_DETAIL_PAGE_OCCASION_TEXT": "Occasion",
      "BASIC_DETAIL_PAGE_BUDGET_TEXT": "Budget",
      "BASIC_DETAIL_PAGE_CITY_TEXT": "City",
      "BASIC_DETAIL_PAGE_PROSPECT_TEXT": "Prospect",
      "BASIC_DETAIL_PAGE_EVENT_DATE_TEXT": "Event Date",
      "BASIC_DETAIL_PAGE_GUEST_TEXT": "Guests",
      "BASIC_DETAIL_PAGE_PACKAGE_TEXT": "Package",
      "BASIC_DETAIL_PAGE_ACTIVE_NOTE_TEXT": "Active Notes",
      "BASIC_DETAIL_PAGE_ADD_NOTE_TEXT": "Add Note",
      "BASIC_DETAIL_PAGE_SAVE_NOTE_TEXT": 'Save Note',




      "EMAIL_PAGE_TITLE": "Email",
      "EMAIL_PAGE_SEND_NEW_MAIL_BUTTON_TEXT": "SEND NEW EMAIL",
      "EMAIL_PAGE_PAST_MAIL_TITLE": "Past Emails",
      "EMAIL_PAGE_EMAIL_SHOW_MORE_TEXT": "Continue",
      "EMAIL_PAGE_SEND_EMAIL_USERNAME_INPUT_TEXT": "Customer Mail ID",
      "EMAIL_PAGE_SEND_EMAIL_METHOD_CHOICE_TITLE": "Select Method to Contact",
      "EMAIL_PAGE_SEND_EMAIL_BUTTON_TEXT": "SEND MAIL",

      "PACKAGE_LIST_PAGE_TITLE": "Packages",
      "PACKAGE_LIST_PAGE_ADD_PACKAGE_BUTTON_TEXT": "ADD PACKAGE",
      "PACKAGE_LIST_PAGE_CLEAR_CACHE": "Clear Cache",
      "PACKAGE_LIST_PAGE_PACKAGE_MENU_TITLE": "Menu",
      "PACKAGE_LIST_PAGE_PACKAGE_ATTACHMENT_TITLE": "Attachments",
      "PACKAGE_LIST_PAGE_PACKAGE_PRICE_TAG": "INR",
      "PACKAGE_LIST_PAGE_PACKAGE_EDIT_BUTTON_TEXT": "EDIT",

      "PACKAGE_PAGE_TITLE": "Package",

      "PACKAGE_PAGE_PACKAGE_NAME_LABEL": "Package Name",
      "PACKAGE_PAGE_PACKAGE_ADD_CUSTOM_PACKAGE": "Add Custom Package",
      "PACKAGE_PAGE_PROPOSED_PACKAGES": "Proposed Packages",
      "PACKAGE_PAGE_PACKAGE_PRICE_LABEL": "Price",
      "PACKAGE_PAGE_PACKAGE_MENU_TITLE": "Menu",
      "PACKAGE_PAGE_PACKAGE_SAVE_BUTTON_TEXT": "SAVE",
      "PACKAGE_PAGE_PACKAGE_ADD_BUTTON_TEXT": "SAVE",

      "MENU_LIST_PAGE_TITLE": "Menu",
      "MENU_LIST_PAGE_ADD_PACKAGE_BUTTON_TEXT": "ADD MENU",
      "MENU_LIST_PAGE_MENU_ITEM_TITLE": "Menu Items",
      "MENU_LIST_PAGE_PACKAGE_ATTACHMENT_TITLE": "Attachments",
      "MENU_LIST_PAGE_PACKAGE_PRICE_TAG": "INR",
      "MENU_LIST_PAGE_MENU_EDIT_BUTTON_TEXT": "EDIT",

      "MENU_PAGE_MENU_NAME_LABEL": "Menu Name",
      "MENU_PAGE_MENU_ITEM_TITLE": "Menu Items",
      "MENU_PAGE_MENU_ITEM_NAME_LABEL": "Item Name",
      "MENU_PAGE_MENU_ITEM_PRICE_LABEL": "Item Price",
      "MENU_PAGE_ADD_NEW_MENU_BUTTON_TEXT": "+ Add",
      "MENU_PAGE_MENU_SAVE_BUTTON_TEXT": "SAVE",
      "MENU_PAGE_MENU_ADD_BUTTON_TEXT": "ADD PACKAGE",

      "SMS_PAGE_TITLE": "SMS",
      "SMS_PAGE_SEND_NEW_SMS_BUTTON_TEXT": "SEND NEW SMS",
      "SMS_PAGE_PAST_MAIL_TITLE": "Past SMS",
      "SMS_PAGE_SMS_SHOW_MORE_TEXT": "Continue",
      "SMS_PAGE_SEND_SMS_PHONE_INPUT_LABEL": "Customer Contact No",
      "SMS_PAGE_SEND_SMS_METHOD_CHOICE_LABEL": "Select Method to Contact",
      "SMS_PAGE_SEND_SMS_INPUT_LABEL": "Sms",
      "SMS_PAGE_SEND_SMS_BUTTON_TEXT": "SEND SMS",

      "VISIT_PAGE_TITLE": "Visit",
      "VISIT_PAGE_TITLE_SAVE": "Save",
      "VISIT_PAGE_ADD_VISIT": "Add Visit",
      "VISIT_PAGE_EDIT_VISIT": "Edit Visit",
      "VISIT_PAGE_VISIT_SHOW_MORE_TEXT": "Continue",
      "VISIT_PAGE_VISIT_DATE_INPUT_LABEL": "Date & Time",
      "VISIT_PAGE_VISIT_STATUS_INPUT_LABEL": "Status",
      "VISIT_PAGE_VISIT_FEEDBACK_INPUT_LABEL": "Feedback",
      "VISIT_PAGE_VISIT_FEEDBACK_SUBMIT_BUTTON_TEXT": "SUBMIT",

      "VISIT_PAGE_ADD_VISIT_SELECT_LABEL": 'Select a visit date',
      "VISIT_PAGE_ADD_VISIT_CUSTOMER_CONFIRM_LABEL": 'Confirmed by Customer',
      "VISIT_PAGE_ADD_VISIT_VENUE_CONFIRM_LABEL": 'Confirmed by Venue',
    },
    "LEADS": {
      "LEADS_DASHBOARD_HEADING": "Leads",
      
      "LEADS_DASHBOARD_LEAD_HEADING_TEXT": "Add Lead",
      "LEADS_DASHBOARD_SUB_HEADING": "Find out if your Customers are getting timely responses",
      "LEADS_DASHBOARD_FILTER_STATUS": 'Status',
      "LEADS_DASHBOARD_FILTER_AGENT": 'Agents',
      "LEADS_DASHBOARD_FILTER_SEARCH_BY_DATE": 'Search by Date',
      "LEADS_DASHBOARD_FILTER_SEARCH_BY_DATE_OPTION_LEAD_DATE": 'Lead Date',
      "LEADS_DASHBOARD_FILTER_SEARCH_BY_DATE_OPTION_LEAD_DATE_Type": 'Date Type',
      "LEADS_DASHBOARD_FILTER_SEARCH_BY_DATE_OPTION_EVENT_DATE": 'Event Date',
      "LEADS_DASHBOARD_FILTER_SEARCH": 'Search',
      "LEADS_DASHBOARD_FILTER_SORT_BY_HEADING": 'Sort by',
      "LEADS_DASHBOARD_FILTER_STATUS_BY_HEADING": 'All',
      "LEADS_DASHBOARD_FILTER_SEARCH_BY_TEXT": 'Search by text',
      "LEADS_DASHBOARD_FILTER_HIDE_SEARCH_BY_TEXT": 'Hide Search by text',
      "LEADS_DASHBOARD_FILTER_SEARCH_PLACEHOLDER": "Search Here",
      "LEADS_DASHBOARD_FILTER_APPLY": "Apply",
      "LEADS_DASHBOARD_FILTER_RESET": "Reset",
      "LEADS_DASHBOARD_FILTER_HEADING": "Filter",
      "LEADS_DASHBOARD_FILTER_DATE_SELECT": "Select Date Range",
      "LEADS_DASHBOARD_FILTER_DATE_SELECT_START": "Select Start Date",
      "LEADS_DASHBOARD_FILTER_DATE_SELECT_END": "Select End Date",


      "LEADS_DASHBOARD_LEAD_LIST_HEADER_ENQUIRY" : 'Enquiry Details',
      "LEADS_DASHBOARD_LEAD_LIST_HEADER_EVENT" : 'Event',
      "LEADS_DASHBOARD_LEAD_LIST_HEADER_ACTIONS" : 'Actions/Status',

      "LEADS_DASHBOARD_LEAD_CONTACT_NAME" : 'Name',
      "LEADS_DASHBOARD_LEAD_CONTACT_MOBILE_NUMBER" : 'Mobile no.',
      "LEADS_DASHBOARD_LEAD_LEAD_DATE" : 'Lead Date',
      "LEADS_DASHBOARD_LEAD_EVENT_DATE" : 'Event Date',

      "LEADS_DASHBOARD_LEAD_OCCASION" : 'Occasion',
      "LEADS_DASHBOARD_LEAD_BUDGET" : 'Budget',
      "LEADS_DASHBOARD_LEAD_BUDGET_SUB" : 'per person',
      "LEADS_DASHBOARD_LEAD_PACKAGE_TYPE" : 'Package Type',
      "LEADS_DASHBOARD_CALL_STATUS_TEXT" : 'Past Call Statuses',
      "LEADS_DASHBOARD_CURRENT_CALL_STATUS_TEXT" : 'Current Call Status',
      "LEADS_DASHBOARD_NEXT_FOLLOW_UP_TEXT" : "Next Follow Up Date",
      "LEADS_DASHBOARD_VISIT_DATE" : "Visit Date",
      "LEADS_DASHBOARD_ACCEPTED_DATE_TEXT" : "Accepted Date",
      "LEADS_DASHBOARD_LEAD_GUEST" : 'Guest',
      "LEADS_DASHBOARD_LEAD_AGENT_NAME_TEXT" : 'Agent Name',
      "LEADS_DASHBOARD_LEAD_CALL_STATUS": 'Call Status',

      "LEAD_DASHBOARD_LEAD_ACTION_MANAGE": 'Manage',
      "LEAD_DASHBOARD_LEAD_ACTION_ACCEPT": 'Accept',
      "LEAD_DASHBOARD_LEAD_ACTION_REJECT": 'Reject',

    },
    "MEMBER": {
      "MEMBER_LIST_PAGE_TITLE": "Members",
      "MEMBER_LIST_PAGE_SUB_HEADING": "Manage your venue members in a few simple clicks",
      "MEMBER_LIST_PAGE_ADD_MEMBER_BUTTON_TEXT": "ADD MEMBER",
      "MEMBER_LIST_PAGE_REVOKE_BUTTON_TEXT": "REVOKE",
      "MEMBER_LIST_PAGE_EDIT_PERMISSION_BUTTON_TEXT": "EDIT",
      "MEMBER_LIST_PAGE_MOBILE_NO_TITLE": "Mobile no.",
      "MEMBER_LIST_PAGE_DESIGNATION_TITLE": "Designation",
      "MEMBER_LIST_PAGE_DEPARTMENT_TITLE": "Department",
      "MEMBER_LIST_PAGE_MEMBER_REVOKE_BUTTON_TEXT": "Delete",
      "MEMBER_LIST_PAGE_MEMBER_EDIT_BUTTON_TEXT": "EDIT",
      "MEMBER_LIST_PAGE_MEMBER_PERMISSION_BUTTON_TEXT": "EDIT PERMISSION",

      "MEMBER_LIST_HEADER_NAME": "Name",
      "MEMBER_LIST_HEADER_EMAIL": "Email ID",
      "MEMBER_LIST_HEADER_MOBILE": "Mobile no",
      "MEMBER_LIST_HEADER_DESIGNATION": "Designation",
      "MEMBER_LIST_HEADER_DEPARTMENT": "Department",
      "MEMBER_LIST_HEADER_ROLE": "Role",
      "MEMBER_LIST_HEADER_ACTION": "Action",

      "MEMBER_PAGE_TITLE": "Member",
      "MEMBER_PAGE_FIRST_NAME_LABEL": "First Name",
      "MEMBER_PAGE_LAST_NAME_LABEL": "Last Name",
      "MEMBER_PAGE_MAIL_LABEL": "Email ID",
      "MEMBER_PAGE_MOBILE_LABEL": "Mobile no.",
      "MEMBER_PAGE_DESIGNATION_LABEL": "Designation",
      "MEMBER_PAGE_DEPARTMENT_LABEL": "Department",
      "MEMBER_PAGE_ROLE_LABEL": "Role",
      "MEMBER_PAGE_SMS_TOGGLE_LABEL": "Recieve SMS",
      "MEMBER_PAGE_NOTIFY_TOGGLE_LABEL": "Receive Notification",
      "MEMBER_PAGE_EMAIL_TOGGLE_LABEL": "Receive Email",
      "MEMBER_PAGE_ADD_BUTTON_TEXT": "SAVE",
    },
    "PAYMENT": {},
    "VENUE": {}
  }
};

export default function getLanguage(lang= 'en'){
  return language[lang];
};

